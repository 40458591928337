import React, { useState, useEffect } from "react";
import { withI18n } from "@lingui/react";
import { Helmet } from "react-helmet";
import { Trans, t } from "@lingui/macro";
import { TOP_BAR_COVER_WIDTH } from "../../constants/image/sizes";
import getIndependentScreenSize from "../../functions/screen/getIndependentScreenSize";
import getRealSize from "../../functions/screen/getRealSize";
import Search from "../Search";
import "./TopBar.css";

/**
 * Icons and images import
 */
import svgLogo from "../Header/assets/WeGoTripLogo.svg";
import backgroundDefaultImageWebp from "./assets/background-default.webp";
import backgroundDefaultImageJpg from "./assets/background-default.jpg";

/**
 * Block with search and advertising abilities
 * @param {Object} props
 * @param {String?} props.background - image got via `import` directive, will be default if not given
 */

// TODO: I would break checkWebPSupport, ImageFormat functions into separate components for less code load

// will check whether the browser supports the WebP format
function checkWebPSupport() {
  const elem = document.createElement("canvas");
  if (elem.getContext && elem.getContext("2d")) {
    return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
  }
  return false;
}

function ImageFormat({ src, onLoad }) {
  return (
    <img
      className="TopBar__background"
      width={getRealSize(getIndependentScreenSize()) || TOP_BAR_COVER_WIDTH}
      height="100%"
      src={src}
      loading="eager"
      onLoad={onLoad}
    />
  );
}

export default withI18n()(({ background, i18n }) => {
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const isWebPSupported = checkWebPSupport();
    const img = new Image();
    img.src =
      background || (isWebPSupported ? backgroundDefaultImageWebp : backgroundDefaultImageJpg);
    img.onload = () => {
      setShowImage(true);
    };

    return () => {
      img.onload = null;
    };
  }, [background]);

  return (
    <div className="TopBar">
      <Helmet>
        <meta property="og:title" content={i18n._(t`Self-guided audio tours on the mobile app`)} />
        <meta
          property="og:description"
          content={i18n._(
            t`Book self-guided audio tours with skip-the-line tickets to museums, galleries, and many other attractions.`,
          )}
        />
        <meta property="og:image" content={svgLogo} />
        <meta property="og:site_name" content="WeGoTrip" />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="TopBar__functional">
        {showImage && (
          <ImageFormat
            src={
              background ||
              (checkWebPSupport() ? backgroundDefaultImageWebp : backgroundDefaultImageJpg)
            }
            onLoad={() => setShowImage(true)}
          />
        )}
        <div className="TopBar__header">
          <div className="Wrapper">
            <div className="TopBar__title">
              <Trans>What will you discover next?</Trans>
            </div>
            <Search className="TopBar__search" theme="big" />
          </div>
        </div>
      </div>
    </div>
  );
});
