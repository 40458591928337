/**
 * Dispatches callback when scroll is ended
 * @param {Number} traceRate - amount of seconds between checks
 * @param {Function} callback - will be called after scroll was ended
 */
export function scrollEnd(traceRate, callback) {
  let timerId = null;
  let scrollStart = null;

  return function ifScrollStopped(event) {
    const { target } = event;

    if (scrollStart === null) scrollStart = { top: target.scrollTop, left: target.scrollLeft };
    if (timerId !== null) clearTimeout(timerId);

    timerId = setTimeout(() => {
      const reversed =
        scrollStart.top - target.scrollTop > 0 || scrollStart.left - target.scrollLeft > 0;
      scrollStart = null;
      callback(target, reversed);
    }, traceRate);
  };
}

/**
 * Animated scroll polyfill
 * @param {Element} target - element which content to scroll
 * @param {Number} to - new scroll coordinate
 * @param {String} axis - prop to set scroll on element (`scrollTop` - default or `scrollLeft`)
 * @param {Number} duration - amount of time used for scrolling (in ms, 500 is default)
 */
export const scrollAnimated = (target, to, axis = "scrollTop", duration = 500) => {
  const start = target[axis];
  const difference = to - target[axis];
  const stopwatch = performance.now();

  function scrollAnimationRequester() {
    let time = (performance.now() - stopwatch) / duration;
    time = time > 1 ? 1 : time;
    // eslint-disable-next-line no-param-reassign
    target[axis] = start + (difference * (Math.sin(Math.PI * (1.5 + time)) + 1)) / 2;

    if (time < 1) {
      window.requestAnimationFrame(scrollAnimationRequester);
    }
  }

  window.requestAnimationFrame(scrollAnimationRequester);
};

/**
 * Scrolls to the nearest child (horizontally)
 * @param {Event} $ - scroll event description
 * @param {Boolean} reversed - if scroll coord was decreased after scroll
 */
export function scrollToNear(target, reversed) {
  const documentWidth = document.documentElement.clientWidth;

  for (let i = 0; i < target.children.length; i += 1) {
    const slide = target.children[i];
    const coords = slide.getBoundingClientRect();
    const diff = coords.width - documentWidth;

    if (
      diff > 0 &&
      ((!reversed && coords.left > -10 - diff) || (reversed && coords.left < 10 + diff))
    ) {
      /* ToDo: unskip linter */
      // eslint-disable-next-line no-continue
      continue;
    }

    if (!reversed && coords.left < -10 && coords.left > -coords.width) {
      scrollAnimated(target, target.scrollLeft + coords.right, "scrollLeft");
      break;
    } else if (
      reversed &&
      coords.right > documentWidth + 10 &&
      coords.right < documentWidth + coords.width
    ) {
      scrollAnimated(target, target.scrollLeft - documentWidth + coords.left, "scrollLeft");
      break;
    }
  }
}
