import Cookies from "./cookies";

/**
 * Key of the cookie with recently viewed tours
 * @type {String}
 */
const recentlyViewedKey = "recentlyViewedTours";

/**
 * Marks tour as recently viewed
 * Stores no more than 10 product ids
 * Works only on client, operate with cookies
 * @param {Number} id - tour id
 * @param {Boolean} allowDuplicate - adds id even if already been added
 */
export function markViewed(id, allowDuplicate) {
  let recentlyViewed = Cookies.get(recentlyViewedKey);

  if (recentlyViewed && recentlyViewed.length) {
    const i = recentlyViewed.indexOf(id);

    if (!allowDuplicate && i !== -1) {
      recentlyViewed.splice(i, 1);
    }
  } else {
    recentlyViewed = [];
  }

  if (recentlyViewed.length > 9) {
    recentlyViewed.pop();
  }

  recentlyViewed.splice(0, 0, id);
  Cookies.set(recentlyViewedKey, recentlyViewed, { path: "/" });
}

/**
 * Gets array with recently viewed tour ids
 * @param {String} cookies - string with cookies for SSR
 */
export function getViewed(cookies) {
  const fromCookie = Cookies.get(recentlyViewedKey, cookies);
  return Array.isArray(fromCookie) ? fromCookie : [];
}
