/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { useMediaQuery } from "beautiful-react-hooks";
import { Trans } from "@lingui/macro";
import isSSR from "../../constants/env/ssr";
import reverseUrl from "../../functions/reverseUrl";
import classNames from "../../functions/classNames";
import inclineIfNeeded from "../../functions/inclineIfNeeded";
import useCurrentLanguage from "../../functions/languages/useCurrentLanguage";
import LocalLink from "../Link";
import { CityCard } from "../CityCard";
import ProductSlider from "../ProductsSlider";
import withArrows from "../ProductsSlider/withArrows";
import "./Cities.css";

const ProductCarousel = withArrows(ProductSlider);

/**
 * Displays cities in a grid on desktop, in a flat slider at mobile
 * Maximum 8 items
 * @param {Object} $
 * @param {String|React::Element} $.title - title of the block
 * @param {Array[Object]} $.cities - descriptions of the cities
 * @param {Number} $.slidesPerPage - amount of visible cards (default is 1)
 * @param {Object} $.country - country relates to current city, if specified, "More cities" link will be rendered
 * @param {Boolean} $.slider - always show in slider
 * @param {Boolean} $.external - open city pages in a new tab
 */
export default function Cities({
  title,
  cities = [],
  country,
  slidesPerPage = 1,
  slider,
  external,
}) {
  const lang = useCurrentLanguage();
  const isDesktop = !isSSR && useMediaQuery("(min-width: 992px)");
  const hasSlider = slider || !isDesktop;

  const withAttractions = cities.length && cities[0].attractions;

  const TabContainer = hasSlider ? ProductCarousel : "span";
  const tabContainerProps = hasSlider
    ? {
        slidesPerPage,
        flatOnMobile: true,
      }
    : {
        className: "row Cities__table",
      };

  return (
    <div className="Cities">
      {title ? <h2 className="h1 m-64-t">{title}</h2> : null}
      <TabContainer {...tabContainerProps}>
        {cities.slice(0, 8).map(city => (
          <div
            key={city.id}
            className={classNames(
              !hasSlider && "col-md-3",
              "Cities__item",
              withAttractions && "Cities__item--wide",
            )}
          >
            <CityCard {...city} external={external} />
          </div>
        ))}
      </TabContainer>
      {country ? (
        <LocalLink
          arrow
          to={reverseUrl("country", {
            lang,
            countryId: country.id,
            countrySlug: country.slug,
          })}
          className="Cities__more"
          theme="heavy"
        >
          <Trans>All cities in {inclineIfNeeded(country.name, "in", lang)}</Trans>
        </LocalLink>
      ) : null}
    </div>
  );
}
