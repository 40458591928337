export default function throttleRAF(func) {
  let inThrottle;
  return function () {
    const args = arguments;
    const context = this;
    if (!inThrottle) {
      window.requestAnimationFrame(() => {
        func.apply(context, args);
        inThrottle = false;
      });
      inThrottle = true;
    }
  };
}
