import { useState, useEffect, useCallback } from "react";
import { isSSR } from "../NoSSR";
import throttleRAF from "../../functions/throttleRAF";
import useStateRef from "../../functions/react/useStateRef";

/**
 * Tracks situation when element with given class disappears/appears into view because of scroll
 * @param {Object} $
 * @param {ScrollHookJustifier} $.children - function returns children to manage visibility
 * @param {String} $.showOn - CSS class of the block disappearing in scroll of which triggers showing `children`
 * @param {String} $.edge - one of borders from `getBoundingClientRect()` to check
 * @param {String} $.once - if exists `onChange` will be dispatched one time - when block was shown or hidden
 * @param {Function} $.onChanged - when `showOn` element is scrolled into/out of view
 * @param {Boolean} $.autohide - hide elements if element with `showOn` class appeared
 *
 */
export default function ScrollHook({
  autohide,
  showOn,
  once,
  edge = "top",
  children = null,
  onChanged = () => {},
  toggleAfterShow = false,
}) {
  const [showContent, showContentRef, setShowContent] = useStateRef(false);
  const [isContentShowed, setIsContentShowed] = useState(false);

  const handleScroll = useCallback(
    throttleRAF(() => {
      const triggerElement = !isSSR && document.querySelector(showOn);

      if (triggerElement) {
        const coords = triggerElement.getBoundingClientRect();

        if (coords[edge] < 80 && !showContentRef.current) {
          setShowContent(true);
          setIsContentShowed(true);
        } else if (coords[edge] >= 80 && showContentRef.current) {
          setShowContent(false);
        }
      }
    }),
  );

  /**
   * Checking for screen-relative position of the element with `showOn` CSS class
   * Showing `children` when targeted element is invisible
   */
  useEffect(() => {
    if (!isSSR) {
      document.addEventListener("scroll", handleScroll);
      return () => document.removeEventListener("scroll", handleScroll);
    }

    return () => {};
  }, []);

  /**
   * Dispatching `onChanged` callback when component is shown
   * Stopping tracking scroll after `onChanged` fired if required
   */
  useEffect(() => {
    if (once) {
      if ((once === "shown" && showContent) || (once === "hidden" && !showContent)) {
        onChanged(showContent);
        return document.removeEventListener("scroll", handleScroll);
      }
    } else {
      onChanged(showContent);
    }
  }, [showContent]);

  if (toggleAfterShow) return isContentShowed ? children : null;

  return !autohide || showContent ? children : null;
}
