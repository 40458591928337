/* eslint-disable import/no-cycle */
import React from "react";
import sendEvent from "../../functions/analytics";
import classNames from "../../functions/classNames";
import { ATTRACTION_COVER_WIDTH } from "../../constants/image/sizes";
import reverseUrl from "../../functions/reverseUrl";
import useCurrentLanguage from "../../functions/languages/useCurrentLanguage";
import "./AttractionCard.css";
import CoverImage from "../CoverImage";
import getIndependentScreenSize from "../../functions/screen/getIndependentScreenSize";
import getAttractionCardMaxWidth from "./getAttractionCardMaxWidth";

/**
 * Attraction card
 * @param {Object} $
 * @param {Number} $.id - id of the attraction
 * @param {Number} $.itemsCount - amount of tours in location
 * @param {String} $.className - additional className for the root Element
 * @param {String} $.name - attraction name
 * @param {String} $.slag - attraction id in the url
 * @param {String} $.preview - background image src
 * @param {String} $.size - size of the card (`"small"`)
 * @param {Object} $.city - description of the city where attraction is located
 * @param {Boolean} $.toursAmount - show amount of tours touches this attraction (`true` by default)
 */
export function AttractionCard({
  id,
  slug,
  name,
  preview,
  className,
  size,
  withTickets = false,
  onClick,
  attractionsMap = false,
  isSelected,
}) {
  const lang = useCurrentLanguage();

  /**
   * Send data into analytics
   */
  const handleAttractionClick = () => {
    sendEvent("track", "Attraction click", {
      Refferer: window.location.pathname,
      type: withTickets ? "ticket" : "attraction",
    });

    if (onClick) {
      onClick();
    }
  };

  return (
    <a
      target="_blank"
      className={classNames(
        "AttractionCard",
        attractionsMap && "AttractionCard__map",
        size && `AttractionCard--${size}`,
        isSelected && "AttractionCard--selected",
        className,
      )}
      href={
        attractionsMap
          ? reverseUrl(withTickets ? "attraction-tickets-simple" : "attraction-simple", {
              attractionId: id,
              attractionSlug: slug,
              lang,
            })
          : undefined
      }
      onClick={handleAttractionClick}
    >
      {attractionsMap && preview ? (
        <CoverImage
          width={getAttractionCardMaxWidth(getIndependentScreenSize()) || ATTRACTION_COVER_WIDTH}
          src={preview}
          className="AttractionCard__image"
          alt={name}
        />
      ) : null}
      <span
        className={`AttractionCard__title ${attractionsMap ? "AttractionCard__title-map" : ""}`}
      >
        {name}
      </span>
    </a>
  );
}
