import { cityIn, incline, cityFrom, cityTo } from "lvovich";

export default function inclineIfNeeded(locationName, type, lang) {
  if (lang === "ru") {
    try {
      if (type === "in") {
        return cityIn(locationName);
      } else if (type === "to") {
        return cityTo(locationName);
      } else if (type === "from") {
        return cityFrom(locationName);
      }
    } catch (error) {
      return locationName;
    }
  }
  return locationName;
}
