import React from "react";
import { Trans } from "@lingui/macro";
import Apps from "../Footer/Apps";
import Image from "../Image";
import "./Banner.css";

/**
 * Importing icons and images
 */
import pngPhones from "./assets/phones.png";

/**
 * Banner with offer to download mobile app
 */
export default () => (
  <div className="Banner">
    <div className="Wrapper">
      <div className="Banner__info">
        <div className="Banner__header">
          <Trans>Self-guided audio tours on mobile app</Trans>
        </div>
        <Trans>
          Book self-guided audio tours with skip-the-line tickets to museums, galleries, and many
          other attractions
        </Trans>
        <Apps />
      </div>
      <Image staticImage className="Banner__image" src={pngPhones} loading="lazy" />
    </div>
  </div>
);
