import { MAX_MOBILE_SCREEN, MAX_TABLET_SCREEN } from "../../constants/screen/devices";
import getRealSize from "../../functions/screen/getRealSize";

/**
 * Calculates attraction card width depending from window width
 * !IMPORTANT: this is rough calculation - originally for lowering image quality
 * @param {Number} screenWidth - max browser window width
 * @return {Number?} - `null` if failed to calculate width
 */
export default function getAttractionCardMaxWidth(screenWidth) {
  if (!screenWidth) {
    return null;
  }

  let width = 135;
  if (screenWidth > MAX_TABLET_SCREEN) {
    width = screenWidth / 6;
  } else if (screenWidth > MAX_MOBILE_SCREEN) {
    width = 160;
  }

  return getRealSize(width * 1.3);
}
