import React, { useState } from "react";
import get from "lodash/get";
import { useSelector, useDispatch } from "react-redux";
import { Trans, t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import classNames from "../../functions/classNames";
import * as types from "../../stores/types";
import { fetchDefaultCurrency } from "../../functions/currency";
import { fetchOverview } from "../../functions/fetchData";
import getCurrentLanguage from "../../functions/languages/getCurrentLanguage";
import useCurrentLanguage from "../../functions/languages/useCurrentLanguage";
import withRedirectToKnownLang from "../../functions/languages/withRedirectToKnownLang";
import Link from "../../components/Link";
import Root from "../../components/_Root";
import { CanonicalAuto } from "../../components/Canonical";
import Breadcrumbs from "../../components/Breadcrumbs";
import Cities from "../../components/Cities";
import ProductsGrid from "../../components/ProductsGrid";
import "./Favorites.css";
import { AlternateAuto } from "../../components/Alternate";
import CookieFooter from "../../components/CookieFooter";

const Head = withI18n()(function PureHead({ isEmpty, i18n }) {
  return (
    <div className="Favorites__head">
      <Breadcrumbs page={{ name: i18n._(t`Favorites`) }} />
      <h1 className="h1 m-32 m-64-t Favorites__title">
        <Trans>Favorites</Trans>
      </h1>
      <div className="Favorites__text">
        {isEmpty ? (
          <>
            <h1 className="h1 m-32 m-64-t Subtitle">
              <Trans>Your wishlist is empty</Trans>
            </h1>
            <Trans>Add tours to your wishlist by clicking on heart button</Trans>
          </>
        ) : null}
      </div>
    </div>
  );
});

function Favorites({ cities }) {
  const lang = useCurrentLanguage();
  const { user, favorites = [] } = useSelector(({ products: { products }, user: storeUser }) => ({
    favorites: products,
    user: storeUser,
  }));
  const favoriteIds = get(user, "user.favorite_products", []);
  const dispatch = useDispatch();
  const [refetched, setRefetched] = useState(false);

  if (!favorites.length && favoriteIds.length && !refetched) {
    setRefetched(true);
    dispatch({ type: types.FETCH_PRODUCTS, ids: favoriteIds, append: true });
  }

  return (
    <Root stickyHeader searchInHeader destinations={cities}>
      <AlternateAuto route="favorites" />
      <CanonicalAuto route="favorites" />
      <div className={classNames("Wrapper", !favorites.length && "Favorites--empty")}>
        <Head isEmpty={!favorites.length} />
        <div className="Favorites__content">
          {favorites.length ? <ProductsGrid products={favorites} /> : null}
          {!favorites.length && cities && cities.length ? (
            <>
              <h1 className="h1 m-32 m-64-t">
                <Trans>Where will you go next?</Trans>
              </h1>
              <Cities external cities={cities} />
              <Link
                external
                arrow
                className="Favorites__more"
                to={`/${lang}/destinations`}
                theme="heavy"
              >
                <Trans>Explore all destinations</Trans>
              </Link>
            </>
          ) : null}
        </div>
        <CookieFooter />
      </div>
    </Root>
  );
}

Favorites.getInitialProps = withRedirectToKnownLang(
  // eslint-disable-next-line no-unused-vars
  async ({ req, res, match, history, location, store, ...ctx }) => {
    const lang = getCurrentLanguage(match.params.lang);
    const cookies = get(req, "headers.cookie");
    store.dispatch({ type: types.FETCH_USER, fetchFavorites: true, cookies });

    const currency = await fetchDefaultCurrency(req);
    store.dispatch({ type: types.SET_DEFAULT_CURRENCY, defaultCurrency: currency });

    const overview = await fetchOverview({ lang }, req);
    const { topCities } = overview;

    return {
      cities: topCities,
    };
  },
);

export default Favorites;
