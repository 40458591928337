/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Trans, t } from "@lingui/macro";
import shuffle from "lodash/shuffle";
import { withI18n } from "@lingui/react";
import { useMediaQuery } from "beautiful-react-hooks";
import ProductSlider from "../ProductsSlider";
import { AttractionCard } from "../AttractionCard";
import withArrows from "../ProductsSlider/withArrows";
import ProductTabs from "../ProductsTabs";
import classNames from "../../functions/classNames";
import "./Attractions.css";
import { getHref } from "../ProductsGrid";
import useCurrentLanguage from "../../functions/languages/useCurrentLanguage";
import { SpinnerCircle } from "../Spinner";
import ProductCard from "../ProductCard";
import { getProductLink } from "../../functions/getProductLink";
import { Icon } from "../Icon";

const ProductCarousel = withArrows(ProductSlider);

export const TopAttractions = withI18n()(function AttractionsTabs({
  attractions,
  withTickets,
  city,
}) {
  const lang = useCurrentLanguage();
  const currency = useSelector(state => state.currencies.defaultCurrency);
  const [selectedAttractionId, setSelectedAttractionId] = useState(null);
  const [productsAttractions, setProductsAttractions] = useState([]);
  const [loading, setLoading] = useState(false);
  const productListId = `city_${city?.slug}_${city?.id}`;

  useEffect(() => {
    if (attractions.length > 0) {
      setSelectedAttractionId(attractions[0].id);
    }
  }, [attractions]);

  useEffect(() => {
    const fetchProducts = async () => {
      if (selectedAttractionId) {
        const perPage = 12;

        const url = `/api/v2/products/popular/?attraction=${selectedAttractionId}&currency=${currency}&page=1&per_page=${perPage}&lang=${lang}&preorder=true&expand=description,images`;

        setLoading(true);
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setProductsAttractions(data.data.results);
        } catch (error) {
          console.error("Error fetching products:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchProducts();
  }, [selectedAttractionId, currency, attractions]);

  const handleAttractionSelect = attractionId => {
    setSelectedAttractionId(attractionId);
  };
  const selectedAttraction = attractions.find(attraction => attraction.id === selectedAttractionId);
  const itemsCount = selectedAttraction?.itemsCount || 0;

  const selectParams = {
    attractionId: selectedAttractionId,
    attractionSlug: attractions.find(attraction => attraction.id === selectedAttractionId)?.slug,
  };

  return (
    <div className="Main__cities TopAttractions">
      <Attractions
        key="top-attractions"
        className="m-16-t"
        withHeading={false}
        withTickets={withTickets}
        attractions={attractions}
        selectedAttractionId={selectedAttractionId}
        onSelect={handleAttractionSelect}
      />

      {loading ? (
        <SpinnerCircle className="Spinner--loading" size="large" theme="dark" />
      ) : (
        <div className="Wrapper__overload">
          <ProductCarousel
            arrowsMini
            flatOnMobile
            chainedScroll
            slidesPerPage={4}
            title={
              <div className="ActivitiesSort__activities">
                <Icon name="found-flag" />
                <Trans>Activities found:</Trans>
                <span className="ActivitiesSort__activities-amount">{itemsCount}</span>
              </div>
            }
          >
            {[
              ...productsAttractions.map((product, i) =>
                product ? (
                  <div key={product.id}>
                    <ProductCard
                      key={product.id}
                      position={i}
                      link={getProductLink(lang, product)}
                      product={product}
                      listId={productListId}
                    />
                  </div>
                ) : null,
              ),
              <div key="placeholder" className="ProductsGrid__placeholder">
                <a
                  href={getHref(selectParams, lang)}
                  className="ProductsGrid__placeholder-card"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="ProductsGrid__placeholder-content">
                    <Trans>See all</Trans>
                    <Icon name="chevron/right" />
                  </div>
                </a>
              </div>,
            ]}
          </ProductCarousel>
        </div>
      )}

      {productsAttractions.length <
        (attractions.find(attraction => attraction.id === selectedAttractionId)?.itemsCount || 0)}
    </div>
  );
});

export const AttractionsTabs = withI18n()(function AttractionsTabs({ i18n, attractions }) {
  const shuffledAttractions = shuffle(attractions);
  return (
    <div className="Main__cities">
      <ProductTabs tabNames={[i18n._(t`Top attractions`), i18n._(t`Ticket sales`)]}>
        <Attractions
          key="top-attractions"
          className="m-16-t"
          withHeading={false}
          attractions={attractions}
        />
        <Attractions
          key="top-tickets-attractions"
          withTickets
          className="m-16-t"
          withHeading={false}
          attractions={shuffledAttractions}
        />
      </ProductTabs>
    </div>
  );
});

function Attractions({
  withHeading = true,
  attractions = [],
  withTickets = false,
  className,
  slidesPerPage = 6,
  onSelect,
  selectedAttractionId,
}) {
  const isDesktop = useMediaQuery("(min-width: 992px)");

  return attractions.length ? (
    <section className={classNames("Attractions", className)}>
      {withHeading ? (
        <h2 className="h1">
          {withTickets ? <Trans>Tickets to top attractions</Trans> : <Trans>Top attractions</Trans>}
        </h2>
      ) : null}
      <div className="Wrapper__overload">
        <ProductCarousel
          flatOnMobile
          disableArrowsAndScrolling={isDesktop}
          slidesPerPage={slidesPerPage}
        >
          {attractions
            .filter(({ fee, entrance, itemsCount }) =>
              Boolean(withTickets ? fee && entrance && itemsCount : itemsCount),
            )
            .map(attraction => (
              <AttractionCard
                key={attraction.id}
                external
                withTickets={withTickets}
                {...attraction}
                isSelected={attraction.id === selectedAttractionId}
                onClick={() => onSelect(attraction.id)}
              />
            ))}
        </ProductCarousel>
      </div>
    </section>
  ) : null;
}

export default Attractions;
