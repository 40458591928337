import { useEffect } from "react";
import { sendEventTrack } from "./analytics";

export const usePageViewTracking = (pageType, props = {}) => {
  useEffect(() => {
    const eventNameMap = {
      main: "main_page_viewed",
      city: "city_page_viewed",
      country: "country_page_viewed",
      category: "category_page_viewed",
      attraction: "attraction_page_viewed",
      product: "product_page_viewed",
    };

    const productEventMap = {
      1: props.preorder ? "preorder_product_page_viewed" : "audioGuide_product_page_viewed",
      2: "ticket_product_page_viewed",
      3: "audioGuide_plus_ticket_product_page_viewed",
    };

    const eventName = eventNameMap[pageType] || "page_viewed";

    const { cityId, countryId, categoryId, attractionId, productId, ...restProps } = props;

    const eventProps = {
      ...restProps,
      ...(pageType === "main"),
      ...(pageType === "city" && { city_id: cityId }),
      ...(pageType === "country" && { country_id: countryId }),
      ...(pageType === "category" && { category_id: categoryId }),
      ...(pageType === "attraction" && { attraction_id: attractionId }),
      ...(pageType === "product" && { product_id: productId }),
    };

    sendEventTrack(eventName, eventProps);

    if (pageType === "product" && productEventMap[props.productType]) {
      sendEventTrack(productEventMap[props.productType], eventProps);
    }
  }, [pageType, props]);
};
