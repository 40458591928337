import React from "react";
import { Trans } from "@lingui/macro";
import { useMediaQuery } from "beautiful-react-hooks";
import { scrollEnd, scrollToNear } from "../../functions/scroll";
import { isSSR } from "../NoSSR";
import "./Benefits.css";
import Image from "../Image";

/**
 * Icons import
 */
import benefitIndependance from "./assets/independance.svg";
import benefitSupport from "./assets/support.svg";
import benefitLanguage from "./assets/language.svg";
import benefitConfirmation from "./assets/confirmation.svg";

/**
 * List of benefits, shown under the search box
 * @type {Array[Object]}
 */
const benefits = [
  {
    icon: benefitIndependance,
    title: <Trans>Travel independently and safely</Trans>,
    text: (
      <Trans>
        Don&apos;t wait for the guide to start the tour. The tour and ticket are always at hand.
      </Trans>
    ),
  },
  {
    icon: benefitSupport,
    title: <Trans>Instant support</Trans>,
    text: <Trans>Chat, phone or email: we are always here to help without endless holds.</Trans>,
  },
  {
    icon: benefitLanguage,
    title: <Trans>100+ self-guided tours</Trans>,
    text: <Trans>Choose high-quality audio tours created by certified guides.</Trans>,
  },
  {
    icon: benefitConfirmation,
    title: <Trans>Instant confirmation</Trans>,
    text: <Trans>You receive tour and tickets right after the booking.</Trans>,
  },
];

/**
 * Shows advantages of WeGoTrip
 */
export default ({ className }) => {
  const isMobile = !isSSR && useMediaQuery("(max-width: 767px)");
  const benefitsScrollEnd = scrollEnd(150, scrollToNear);

  return (
    <div className={className}>
      <h2 className="h1 m-32 m-64-t">
        <Trans>What is WeGoTrip</Trans>
      </h2>
      <div className="Benefits" onScroll={isMobile ? benefitsScrollEnd : () => {}}>
        {benefits.map(({ icon, title, text }) => (
          <div key={icon} className="Benefits__item">
            <Image staticImage className="Benefits__icon" src={icon} loading="lazy" />
            <div className="Benefits__content">
              <div className="Benefits__title">{title}</div>
              <div className="Benefits__text">{text}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
